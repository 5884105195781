<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-button type="primary" class="add-button" @click="onAdd">新增</a-button>
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
        :scroll="{ x: 1000 }"
      >
        <template slot="signStatusName">
          签署状态
          <a-tooltip>
            <template slot="title">
              订单合同的签署状态
            </template>

            <a-icon type="info-circle" />
          </a-tooltip>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a @click="onDetail(record)">查看</a>
          </a-space>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import Breadcrumb from "@/components/Breadcrumb";
import { tsFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import {
  fetchAllOrderList,
  fetchCorporationInfoByKeyword,
  fetchSellerCorporationByKeyword
} from "@/services/OrderManageService";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: 260
  },
  {
    title: "买方机构",
    dataIndex: "purchaserCorporationName",
    width: 200
  },
  {
    title: "收货地区",
    dataIndex: "consigneeAreaName",
    width: 200
  },
  {
    title: "商品数量",
    dataIndex: "totalQuantity",
    width: 100
  },
  {
    title: "出厂价总额（元）",
    dataIndex: "totalFactoryPrice",
    width: 100
  },
  {
    title: "订单总额（元）",
    dataIndex: "totalPrice",
    width: 100
  },
  {
    title: "卖方机构",
    dataIndex: "producerCorporationName",
    width: 100
  },
  {
    title: "订单状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: 140
  },
  {
    dataIndex: "signStatus",
    slots: { title: "signStatusName" },
    width: 140
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 120,
    fixed: "right"
  }
];

export default {
  name: "OperationPurchaseOrderList",
  mixins: [pageDetailMixins],
  components: {
    Breadcrumb
  },
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "买方机构",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "卖方机构",
          key: "producerDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchSellerCorporation(value);
          }
        },
        {
          label: "收货地区",
          key: "consigneeProvinceCode,consigneeCityCode,consigneeCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        },
        {
          label: "订单状态",
          key: "orderStatus",
          component: "j-select",
          defaultValue: "all",
          options: [
            {
              label: "全部",
              value: "all"
            },
            {
              label: "卖家审核中",
              value: "to_audit"
            },
            {
              label: "买家付款中",
              value: "to_payment"
            },
            {
              label: "待付款审核",
              value: "to_hq_payment_approval"
            },
            {
              label: "签约中",
              value: "signing"
            },
            {
              label: "已取消",
              value: "canceled"
            },
            {
              label: "已退回",
              value: "returned"
            },
            {
              label: "交易成功",
              value: "complete"
            },
            {
              label: "卖家发货中",
              value: "to_deliver"
            },
            {
              label: "买家收货中",
              value: "to_receive"
            }
          ]
        },
        {
          label: "下单时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {
        orderStatus: "all"
      },
      areaData: [],
      tblColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      tsFormat
    };
  },
  mounted() {
    this.loadAreaData();
    // this.loadList();
  },
  activated() {
    if (this.$store.state.hasChange) {
      this.pagination.current = 1;
      this.$store.state.hasChange = false;
    }
    this.loadList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 搜索下单机构
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 搜索卖方机构
    async onSearchSellerCorporation(value) {
      let options = [];
      const resp = await fetchSellerCorporationByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        orderSn: this.form.orderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        consigneeProvinceCode: this.form.consigneeProvinceCode
          ? this.form.consigneeProvinceCode
          : "",
        consigneeCityCode: this.form.consigneeCityCode
          ? this.form.consigneeCityCode
          : "",
        consigneeCountyCode: this.form.consigneeCountyCode
          ? this.form.consigneeCountyCode
          : "",
        orderType: this.form.orderType ? this.form.orderType : "",
        orderStatus: this.form.orderStatus ? this.form.orderStatus : "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        signStatus: this.form.signStatus ? this.form.signStatus : "",
        orderCategory: "PURCHASE",
        factoryId: "",
        producerDistributorId: this.form.producerDistributorId || "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        replaceFlag: true // 是否是代下订单
      };
      fetchAllOrderList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 审核
    onExamine(orderSn) {
      this.$router.push({
        name: "PurchaseOrderExamineOperation",
        params: { orderSn: orderSn, type: "examine" }
      });
    },
    // 查看
    onDetail(record) {
      if (record.orderTypeValue === "远期订单") {
        this.$router.push({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: record.orderSn, status: record.orderStatusValue }
        });
      } else {
        this.$router.push({
          name: "PurchaseOrderDetailOperation",
          params: {
            orderSn: record.orderSn,
            type: "detail",
            status: record.orderStatusValue
          }
        });
      }
    },
    onAdd() {
      this.$router.push({ name: "OperationAgentOrderAdd" });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}

.status--orange {
  color: #ff8600;
}

.status--red {
  color: #ff596a;
}

.status--green {
  color: #08c484;
}
.add-button {
  margin-bottom: 12px;
}
</style>
